h1 {
    font-weight: 300;
    text-align: center;
    margin-bottom: 24px;
}

h2 {
    font-weight: 300;
    text-align: center;
    margin-bottom: 24px;
}