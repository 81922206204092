//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "typography";
// @import "fonts";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
  background-image: url(../images/start.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.navbar {
  color: #ffffff;
  transition: background-color 1.5s ease;
  background-color: rgba(1, 129, 166, 0);
  backdrop-filter: blur(6.4px);

  &.scroll {
    background-color: rgba(1, 129, 166, 0.7);
    backdrop-filter: blur(6.4px);
  }
}

.flag-icon {
  height: 16px;
}

.start {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 56px);
  color: #ffffff;
  padding: 100px 0 0 0;

  .download-teams {
    margin-top: 30px;
    margin-bottom: 30px;
  }


  .screenshot {
    padding: 48px;
    z-index: 1;

    video {
      width: 100%;
      height: auto;
      // drop shadow
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      border-radius: 8px;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .start {
    min-height: 900px;
  }
}

@include media-breakpoint-down(lg) {
  .start .screenshot {
    padding: 48px 0;

    video {
      width: 96vw;
    }
  }
}

.white {
  background-color: rgba(255, 255, 255, 0.9);
  min-height: 100vh;
  backdrop-filter: blur(7px);
  z-index: 2;
  display: flex;
}

.full-white {
  background-color: #ffffff;
  min-height: 100vh;
}

footer {
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(7px);

  .container {
    padding: 148px 0;
  }

  color: #ffffff;

  a,
  a:hover,
  a:active,
  a:visited {
    color: #ffffff;
  }
}

#features {
  margin-top: 80px;
  padding: 80px 0 120px 0; // top padding for scroll target

  .feature {

    .carousel-inner {
      position: relative;
    }

  }
}



@include media-breakpoint-up(xl) {
  #features {
    margin-top: 200px;
  }
}

@include media-breakpoint-up(xxl) {
  #features {
    margin-top: 310px;
  }
}

#pricing {
  padding-top: 100px;
  padding-bottom: 300px;

  .pricing-description {
    margin-bottom: 48px;
    font-size: 20px;
  }

  .pricing-card-title {
    margin-bottom: 12px;
    margin-top: 24px;
  }

  .card-body {
    height: 300px;
    display: flex;
    flex-direction: column;

    button,
    a {
      margin-top: auto;
    }
  }

  small {
    font-size: 18px !important;
    line-height: 1 !important;
    margin-bottom: 12px;
  }
}

#faq {
  padding-bottom: 300px;
  padding-top: 200px;
  background-color: #ffffff;

  .faq-content {
    max-width: 800px;
  }

  a {
    color: #000000;
    text-decoration: none;
  }

  h2 {
    text-align: left;
  }

  .note {
    font-size: 26px;
    font-weight: 600;
    margin-top: 48px;
  }
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

// @import "components/slider";